import { useReactiveVar } from "@apollo/client";
import clsx from "clsx";
import { useTranslation } from "next-i18next";
import Link from "next/link";
import { useRouter } from "next/router";
import { Dispatch, SetStateAction } from "react";

import { hasAccessTokenVar } from "@/apollo/links/auth";
import { DetailedPrivateUserFragment } from "@/graphql/types";
import { useWindowSize } from "@/components/WindowSizeProvider";
import DesktopAuth from "@/components/navbar/DesktopAuth";
import MobileAuth from "@/components/navbar/MobileAuth";

type ConnectButtonsProps = {
  me?: DetailedPrivateUserFragment;
  meLoading: boolean;
  showMobileMenu: boolean;
  setShowMobileMenu: Dispatch<SetStateAction<boolean>>;
};

function ConnectButtons({ me, meLoading, showMobileMenu, setShowMobileMenu }: ConnectButtonsProps) {
  const router = useRouter();
  const isLogged = useReactiveVar(hasAccessTokenVar);

  const { t } = useTranslation("common");
  const { width } = useWindowSize();
  const isMobile = width < 1024;

  if (isMobile)
    return (
      <MobileAuth
        me={me}
        showMobileMenu={showMobileMenu}
        setShowMobileMenu={setShowMobileMenu}
        key={me?.id}
      />
    );

  if (!isLogged || (!me && !meLoading))
    return (
      <div className="hidden lg:flex items-center flex-shrink-0">
        <Link href={`/connect?login=true&redirect=${encodeURIComponent(router.asPath)}`}>
          <a
            className={clsx(
              "hidden xl:block font-medium tracking-wider mr-4 xl:mr-6",
              "cursor-pointer uppercase lg:hover:text-purple-3 transition",
              "ease-quart-out duration-300 text-white"
            )}
          >
            {t("common.navbar.auth.login")}
          </a>
        </Link>
        <Link href={`/connect?redirect=${encodeURIComponent(router.asPath)}`}>
          <a
            className={clsx(
              "font-medium tracking-wider rounded-full h-10 flex",
              "items-center px-5 cursor-pointer uppercase border-2",
              "transition ease-quart-out duration-500",
              "text-grey-dark bg-white border-white lg:hover:bg-transparent lg:hover:text-white"
            )}
          >
            {t("common.navbar.auth.signup")}
          </a>
        </Link>
      </div>
    );

  return <DesktopAuth me={me} key={me?.id} />;
}

export default ConnectButtons;
