import { AnimatePresence, motion } from "framer-motion";
import { Dispatch, SetStateAction, useContext } from "react";
import clsx from "clsx";
import Image from "next/image";

import NotificationList from "@/components/notifications/NotificationList";
import { MobileAuthNavbar } from "@/components/navbar/MobileAuth";
import { DetailedPrivateUserFragment } from "@/graphql/types";
import { NotificationsContext } from "@/utils/reactContexts";
import DesktopAuth from "@/components/navbar/DesktopAuth";
import SearchInput from "@/components/navbar/SearchInput";
import { easeQuartOut } from "@/utils/constants";
import Link from "@/components/hoc/CustomLink";

type Props = {
  me: DetailedPrivateUserFragment;
  isMobile: boolean;
  navbarIsTop: boolean;
  setShowMobileMenu: Dispatch<SetStateAction<boolean>>;
};

export default function Notifications({ me, isMobile, navbarIsTop, setShowMobileMenu }: Props) {
  const { showNotifications, setShowNotifications } = useContext(NotificationsContext);

  const enterVariants = isMobile
    ? {
        hidden: {
          y: "100%",
        },
        visible: {
          y: 0,
        },
      }
    : {
        hidden: {
          x: "100%",
          opacity: 0,
        },
        visible: {
          x: 0,
          opacity: 1,
        },
      };

  if (isMobile === undefined) return null;

  return (
    <AnimatePresence exitBeforeEnter>
      {showNotifications && (
        <motion.div
          className={clsx("fixed inset-0 h-full w-full max-h-full flex justify-end z-100")}
          onClick={isMobile ? undefined : () => setShowNotifications(false)}
        >
          <motion.div
            initial={{ opacity: 0, backdropFilter: "opacity(0) blur(12px)" }}
            animate={{ opacity: 1, backdropFilter: "opacity(1) blur(12px)" }}
            exit={{ opacity: 0, backdropFilter: "opacity(0) blur(12px)" }}
            className={clsx(
              "absolute inset-0 bg-dark/90 bg-dark-glass lg:bg-dark-3/10 lg:bg-none",
              "before:absolute before:inset-0 before:-z-10 before:rounded-md before:backdrop-blur-md"
            )}
          />
          <div className={"h-full w-full lg:w-min flex flex-col relative flex-shrink-0 z-10"}>
            {!isMobile && (
              <motion.div
                id="notifications-drawer-bg"
                className={clsx(
                  "w-full absolute inset-y-0 right-0 h-screen bg-gradient-to-top from-purple/5 to-purple/5",
                  "bg-dark-2/40 backdrop-filter backdrop-blur-2xl -z-5"
                )}
                onClick={(e) => e.stopPropagation()}
                variants={{
                  open: { opacity: 1, backdropFilter: "opacity(1) blur(48px)" },
                  closed: { opacity: 0, backdropFilter: "opacity(0) blur(48px)" },
                }}
                initial="closed"
                animate="open"
                exit="closed"
                transition={{
                  ease: easeQuartOut,
                }}
              />
            )}
            {isMobile ? (
              <div className="flex items-center justify-between container py-5">
                <Link href="/">
                  <a className="mr-4 lg:mr-0 relative h-4.5 lg:h-5.5 w-35 lg:w-42.5">
                    <Image
                      src={"/assets/logo/pianity-white.svg"}
                      alt="logo"
                      className="cursor-pointer"
                      layout="fill"
                      sizes="256px"
                    />
                  </a>
                </Link>
                <MobileAuthNavbar me={me} onMenuClick={() => setShowMobileMenu(true)} />
              </div>
            ) : (
              <div
                className={clsx(
                  "flex justify-end pr-container z-10 flex-shrink-0",
                  navbarIsTop ? "pt-8" : "py-4"
                )}
                onClick={(e) => e.stopPropagation()}
              >
                <SearchInput />
                <DesktopAuth me={me} key={me.id} />
              </div>
            )}
            <motion.div
              key="notifications-animated-drawer"
              className={clsx(
                "lg:ml-6 xl:ml-10 pt-5 flex flex-col h-full overflow-y-auto",
                isMobile ? "container" : "mr-container"
              )}
              onClick={(e) => e.stopPropagation()}
              variants={enterVariants}
              initial="hidden"
              animate="visible"
              exit="hidden"
              transition={{
                ease: easeQuartOut,
              }}
            >
              <NotificationList me={me} />
            </motion.div>
          </div>
        </motion.div>
      )}
    </AnimatePresence>
  );
}
