import { DetailedPrivateUserFragment, UserAuthorization } from "@/graphql/types";

export default function getAdminDashboardPath(me: DetailedPrivateUserFragment): string | undefined {
  const auths = me.authorizations;
  if (
    auths.includes(UserAuthorization.ReadArtistIntent) &&
    auths.includes(UserAuthorization.ReadBuilderTrackIntent)
  )
    return "/admin";
  if (auths.includes(UserAuthorization.ReadBuilderTrackIntent)) return "/admin/mint";
  if (auths.includes(UserAuthorization.ReadArtistIntent)) return "/admin/artists";
  return undefined;
}
