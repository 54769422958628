import { useTranslation } from "next-i18next";
import { useEffect, useRef, useState } from "react";
import { LayoutGroup } from "framer-motion";
import { useQuery, useReactiveVar } from "@apollo/client";
import clsx from "clsx";

import { useMarkAllNotificationsAsReadMutation } from "@/utils/mutationHooks";
import NotificationStack from "@/components/notifications/NotificationStack";
import LoadingIndicator from "@/components/ui/svg/LoadingIndicator";
import { groupNotifsBy } from "@/utils/notifications";
import { hasAccessTokenVar } from "@/apollo/links/auth";
import { getCurrencyCode } from "@/utils/currencies";
import groupBy from "@/utils/groupBy";
import {
  Currency,
  DetailedPrivateUserFragment,
  GetNotificationsDocument,
  GetNotificationsQuery,
} from "@/graphql/types";

type Notification = GetNotificationsQuery["notifications"]["results"][0];

type Props = {
  me: DetailedPrivateUserFragment;
};

export default function NotificationList({ me }: Props) {
  const isLogged = useReactiveVar(hasAccessTokenVar);
  const { t } = useTranslation("notifications");

  const scrollContainerRef = useRef<HTMLDivElement>(null);

  const [stackedNotifs, setStackedNotifs] = useState<Notification[][]>();

  const { data, loading, fetchMore } = useQuery(GetNotificationsDocument, {
    skip: !isLogged,
    variables: {
      currency: getCurrencyCode() as string as Currency,
      isRead: false,
      limit: 25,
    },
    notifyOnNetworkStatusChange: true,
  });

  const [markAllAsReadMutation, { loading: markAsReadLoading }] = useMarkAllNotificationsAsReadMutation({
    refetchQueries: [GetNotificationsDocument],
  });

  useEffect(() => {
    if (data) {
      const notifs = data.notifications.results.filter(
        (n) => n.__typename !== "PaymentNotification" || n.payment
      );

      const grouped = groupBy(notifs, (notif) => notif.type);
      const stacked = Object.entries(grouped).map((notifs) => {
        const key = (n: Notification) => groupNotifsBy(n);

        if (!notifs[1][0] || !key(notifs[1][0])) return [notifs[1]];
        return Object.entries(groupBy(notifs[1], key as (n: Notification) => string)).map(
          (stacks) => stacks[1]
        );
      }) as Notification[][][];
      setStackedNotifs(stacked.flat());
    }
  }, [data]);

  if (
    data &&
    !loading &&
    data.notifications.results.length < data.notifications.size &&
    scrollContainerRef.current &&
    scrollContainerRef.current.scrollHeight <= scrollContainerRef.current.clientHeight
  ) {
    fetchMore({
      variables: {
        offset: data.notifications.results.length,
      },
    });
  }

  return (
    <>
      <div className="flex items-end justify-between">
        <div className={"text-white text-28 leading-1 font-medium"}>{t("notifications.title")}</div>
        {loading || markAsReadLoading ? (
          <LoadingIndicator width="14" height="14" />
        ) : (
          <button
            onClick={() => markAllAsReadMutation()}
            className={clsx(
              "h-5 px-2 rounded-full bg-purple-3 bg-opacity-50 flex",
              "items-center text-white text-10 leading-0.75 tracking-wider",
              "uppercase font-medium"
            )}
          >
            {t("notifications.clear_all")}
          </button>
        )}
      </div>
      <div
        className={clsx(
          "mt-4 text-purple-5 text-xs leading-0.75 tracking-wider",
          "uppercase flex items-center gap-1"
        )}
      >
        {t("notifications.unread", { count: me.unreadNotificationsCount ?? 0 })}
      </div>

      <div
        ref={scrollContainerRef}
        className={clsx(
          "h-full mt-6 flex flex-col gap-3 lg:gap-4 pb-3 lg:pb-4",
          "flex-grow flex-shrink overflow-y-auto"
        )}
        onScroll={(event) => {
          // Reached end of list
          if (data && data.notifications.results.length >= data.notifications.size) return;

          const element = event.target as HTMLDivElement;
          // Scrolled within 30px of bottom
          if (element.scrollHeight - element.scrollTop <= element.clientHeight + 30) {
            if (!loading) {
              fetchMore({
                variables: {
                  offset: data?.notifications.results.length ?? 0,
                },
              });
            }
          }
        }}
      >
        <LayoutGroup>
          {stackedNotifs?.map((notifications, i) => (
            <NotificationStack
              notifications={notifications}
              key={"stack" + i}
              stackId={"stack-layout-" + i}
            />
          ))}
        </LayoutGroup>
      </div>
    </>
  );
}
