import { Dispatch, SetStateAction, useContext, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useRouter } from "next/router";
import Image from "next/image";
import clsx from "clsx";

import { checkUserWalletApproveState } from "@/utils/hooks/useDisplayUserState";
import NotificationBadge from "@/components/notifications/NotificationBadge";
import { NotificationsContext } from "@/utils/reactContexts";
import { DetailedPrivateUserFragment } from "@/graphql/types";
import MobileMenu from "@/components/navbar/MobileMenu";
import WalletIcon from "@/components/ui/svg/WalletIcon";
import { getAuthUser } from "@/utils/oAuth/storage";
import { useWallet } from "@/components/Wallet";
import { getButtonClasses } from "@/components/ui/Button";
import Link from "@/components/hoc/CustomLink";
import { getDefaultAuthType } from "@/components/connect/AuthModal/helpers";
import { getAnalytics } from "@/utils/analytics";

type Props = {
  me: DetailedPrivateUserFragment | undefined;
  showMobileMenu: boolean;
  setShowMobileMenu: Dispatch<SetStateAction<boolean>>;
};

export default function MobileAuth({ me, showMobileMenu, setShowMobileMenu }: Props) {
  useEffect(() => {
    if (showMobileMenu) {
      document.body.style.overflowY = "hidden";
      document.body.style.display = "fixed";
    } else {
      document.body.style.overflowY = "unset";
      document.body.style.display = "unset";
    }

    return () => {
      document.body.style.overflowY = "unset";
      document.body.style.display = "unset";
    };
  }, [showMobileMenu]);

  return (
    <div className="flex-shrink-0">
      <MobileAuthNavbar me={me} onMenuClick={() => setShowMobileMenu(true)} />
      <MobileMenu me={me} show={showMobileMenu} close={() => setShowMobileMenu(false)} />
    </div>
  );
}

type NavbarProps = {
  me: DetailedPrivateUserFragment | undefined;
  onMenuClick?: () => void;
};

export function MobileAuthNavbar({ me, onMenuClick }: NavbarProps) {
  const { t } = useTranslation("common");
  const loggedUser = getAuthUser();
  const router = useRouter();

  const { setShowWallet } = useWallet();
  const { showNotifications } = useContext(NotificationsContext);

  const user = me ?? loggedUser;
  const authType = getDefaultAuthType();
  return (
    <div className="flex items-center">
      {!user && (
        <Link href={`/connect?login=${authType === "login"}&redirect=${encodeURIComponent(router.asPath)}`}>
          <a
            className={getButtonClasses({
              variants: { intent: "purple" },
              height: { base: 8 },
              paddingX: { base: 3.5 },
            })}
            onClick={() => {
              getAnalytics()?.track("WEB - Auth - Mobile Navbar Connect Button - Clicked", {
                authType,
              });
            }}
          >
            <span className="text-sm leading-1.2 font-medium uppercase">
              {t(`common.navbar.auth.${authType}`)}
            </span>
          </a>
        </Link>
      )}

      <div
        onClick={onMenuClick}
        className={clsx(
          "h-8 w-8 flex items-center justify-center font-medium",
          "tracking-wider uppercase text-white",
          !user && "ml-2.5"
        )}
      >
        <svg width="18" height="12" viewBox="0 0 18 12" fill="none" xmlns="http://www.w3.org/2000/svg">
          <rect width="18" height="2" rx="0.5" className="fill-current" />
          <rect x="4" y="5" width="14" height="2" rx="0.5" className="fill-current" />
          <rect y="10" width="18" height="2" rx="0.5" className="fill-current" />
        </svg>
      </div>
      {user && (
        <div
          onClick={(e) => {
            e.stopPropagation();
            if (!me || (me.unreadNotificationsCount === 0 && !showNotifications)) onMenuClick?.();
          }}
          className={clsx(
            "h-10 w-10 rounded-full bg-white text-grey-dark flex",
            "items-center justify-center shadow-medium relative"
          )}
        >
          <Image src={user.avatarUrl} width="40" height="40" alt="" className="rounded-full object-cover" />
          {me && (me.unreadNotificationsCount > 0 || showNotifications) && (
            <NotificationBadge count={me.unreadNotificationsCount} />
          )}
        </div>
      )}
      {user && (
        <div
          className={clsx(
            "ml-2 h-10 w-10 bg-purple-5 md:hover:bg-opacity-50",
            "rounded-full flex items-center justify-center shadow-medium"
          )}
          onClick={(e) => {
            e.stopPropagation();
            if (checkUserWalletApproveState(user, t, "wallet")) {
              setShowWallet(true);
            }
          }}
        >
          <WalletIcon />
        </div>
      )}
    </div>
  );
}
