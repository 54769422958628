import { useTranslation } from "next-i18next";

import { BasicLabelFragment } from "@/graphql/types";
import { useDeclineLabelInvitationMutation, useMarkNotificationsAsReadMutation } from "@/utils/mutationHooks";
import { markNotificationsAsReadUpdate } from "@/apollo/cache/updates/notifications";

interface Props {
  /** Label extending the invitation */
  label: BasicLabelFragment;
  /** ID of the notification to mark as read on decline */
  notificationId?: string;
}

/**
 * Renders a toast for a label invitation to a user.
 *
 * Has an accept button which redirects to artist registration
 * and a decline button which marks the notification as read.
 */
export default function UserLabelInvitationToast({ label, notificationId }: Props) {
  const { t } = useTranslation("notifications");

  const [markAsReadMutation] = useMarkNotificationsAsReadMutation();
  const [declineMutation] = useDeclineLabelInvitationMutation();

  async function declineInvitation() {
    await declineMutation({ variables: { labelId: label.id } });

    if (notificationId) {
      await markAsReadMutation({
        variables: { notificationIds: [notificationId] },
        update: markNotificationsAsReadUpdate,
      });
    }
  }

  return (
    <div>
      {t("notifications.messages.account.artist.label.invite.message_user", {
        label: label.name,
      })}
      <div className="flex items-center mt-3 gap-3">
        <button className="block text-green-5 text-xs leading-1 font-medium uppercase tracking-wider">
          {t("notifications.messages.account.artist.label.invite.accept")}
        </button>
        <button
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            declineInvitation();
          }}
          className="block text-orange-3 text-xs leading-1 font-medium uppercase tracking-wider"
        >
          {t("notifications.messages.account.artist.label.invite.decline")}
        </button>
      </div>
    </div>
  );
}
