import { DetailedPrivateUserFragment } from "@/graphql/types";
import { getAnalytics } from "@/utils/analytics";
import { unwrapMaybe } from "@/utils/graphql";

/**
 * Calls analytics to identify logged in user
 * with all appropriate data.
 *
 * @param me - user to identify
 */
export function identifyMe(me: DetailedPrivateUserFragment) {
  if (!me) return;

  getAnalytics()?.identify(me.slug, {
    userId: me.id,
    slug: me.slug,
    username: me.username,
    avatarUrl: me.avatarUrl,
    email: unwrapMaybe(me.email),
    emailVerified: me.isEmailVerified,
    artist: me.artist
      ? {
          artistId: me.artist?.id,
          slug: me.artist?.slug,
          name: me.artist?.name,
          avatarUrl: unwrapMaybe(me.artist.avatarUrl),
          followersCount: me.artist?.followersCount,
        }
      : undefined,
    label: me.label?.slug,
    authorizations: me.authorizations.slice(),
    transactionTypeUser: me.transactionType,
    walletApproveState: unwrapMaybe(me.walletApproveState),
    arweaveAddress: unwrapMaybe(me.arweaveAddress),
    discordUsername: unwrapMaybe(me.discordUsername),
    discordUID: unwrapMaybe(me.discordUid),
    unreadNotificationsCount: me.unreadNotificationsCount,
    referrer: me.referrer?.slug,
    pia: me.pia,
    wallet: {
      amount: me.wallet?.amount,
      currency: me.wallet?.currency,
    },
    hasCompletedOnboarding: me.hasCompletedOnboarding,
    favoriteGenres: me.favoriteGenres.map((g) => g.slug),
    howDoYouWantToUse: unwrapMaybe(me.howDoYouWantToUse),
    createdAt: Math.floor(new Date(me.createdAt).getTime() / 1000),
    firstLogin: false,
  });
}
