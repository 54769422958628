import clsx from "clsx";

import ConditionalLink from "@/components/hoc/ConditionalLink";
import Link from "@/components/hoc/CustomLink";
import Chevron from "@/components/ui/svg/Chevron";

interface NavigationDropdownProps {
  label: string;
  href?: string;
  items: { label: string; href: string }[];
}

function NavigationDropdown({ label, href, items }: NavigationDropdownProps) {
  return (
    <div
      className={clsx(
        "group hidden lg:flex items-center mr-4 xl:mr-5 text-base",
        "leading-1 font-medium tracking-wider uppercase relative",
        "cursor-pointer transition ease-quart-out duration-300",
        "lg:hover:text-purple-2"
      )}
    >
      <ConditionalLink href={href}>{label}</ConditionalLink>
      <Chevron className="rotate-180 ml-2" width={8} height={5} />
      <div
        className={clsx(
          "tracking-normal flex pt-2 absolute bottom-0 translate-y-full",
          "invisible group-hover:visible"
        )}
      >
        <ul
          className={clsx(
            "p-1 shadow-medium rounded-md flex flex-col capitalize w-max relative",
            "text-white bg-dark-glass bg-dark-4/70 backdrop-blur-lg",
            "opacity-0 backdrop-opacity-0 group-hover:opacity-100 group-hover:backdrop-opacity-100",
            "transition duration-300 ease-quart-out",
            "before:border-[1.5px] before:bg-dark-stroke",
            "before:border-gradient before:rounded-md before:backdrop-blur-lg"
          )}
        >
          {items.map((item) => (
            <li key={item.href}>
              <Link href={item.href}>
                <a className="h-10 px-4 flex items-center hover:bg-dark-hover rounded font-medium">
                  {item.label}
                </a>
              </Link>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
}

export default NavigationDropdown;
