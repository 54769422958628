import { useTranslation } from "next-i18next";
import { toast as toastify } from "react-toastify";
import { useState } from "react";

import {
  useAcceptCollabInvitationMutation,
  useDeclineCollabInvitationMutation,
  useMarkNotificationsAsReadMutation,
} from "@/utils/mutationHooks";
import LoadingIndicator from "@/components/ui/svg/LoadingIndicator";
import { toast } from "@/utils/toasts";
import { BasicTrackFragment } from "@/graphql/types";

interface Props {
  track: BasicTrackFragment;
  notificationId: string;
}
export default function CollabInvitationToast({ track, notificationId }: Props) {
  const { t } = useTranslation("notifications");

  const [processing, setProcessing] = useState(false);

  const [acceptMutation] = useAcceptCollabInvitationMutation();
  const [declineMutation] = useDeclineCollabInvitationMutation();
  const [markAsReadMutation] = useMarkNotificationsAsReadMutation();

  async function respondToInvitation(accept: boolean) {
    setProcessing(true);

    const variables = { trackId: track.id };
    const mutation = accept ? await acceptMutation({ variables }) : await declineMutation({ variables });

    if (!mutation.errors) {
      toast.success(
        t(`notifications.messages.waitlist.artist.collab.${accept ? "accepted" : "declined"}_me`, {
          track: track.title,
        })
      );
      await markAsReadMutation({ variables: { notificationIds: [notificationId] } });
    }
    toastify.dismiss("collab-invite-" + track.id);
    setProcessing(false);
  }

  return (
    <div>
      {t("notifications.messages.waitlist.artist.collab.invited", {
        track: track.title,
        artist: track.artists[0]?.name,
      })}
      {processing ? (
        <div className="text-purple">
          <LoadingIndicator height="15" width="15" />
        </div>
      ) : (
        <div className={"flex items-center mt-3 gap-3"} onClick={(e) => e.stopPropagation()}>
          <button
            onClick={() => respondToInvitation(true)}
            className="block text-green-5 text-xs leading-1 font-medium uppercase tracking-wider"
          >
            {t("notifications.messages.account.artist.label.invite.accept")}
          </button>
          <button
            onClick={() => respondToInvitation(false)}
            className="block text-orange-3 text-xs leading-1 font-medium uppercase tracking-wider"
          >
            {t("notifications.messages.account.artist.label.invite.decline")}
          </button>
        </div>
      )}
    </div>
  );
}
