import { useTranslation } from "next-i18next";
import clsx from "clsx";

import CloseButton from "@/components/ui/svg/CloseButton";

export default function ClearButton({ onClick, disabled }: { onClick: () => void; disabled: boolean }) {
  const { t } = useTranslation("notifications");

  return (
    <button
      onClick={onClick}
      disabled={disabled}
      className={clsx(
        "px-1.5 min-w-5 h-5 flex items-center justify-center",
        "bg-purple bg-opacity-70 text-white rounded-full",
        "cursor-pointer group"
      )}
    >
      <div className={"hidden group-hover:block text-10 uppercase"}>{t("notifications.clear")}</div>
      <CloseButton className="w-2.5 h-2.5 group-hover:hidden" />
    </button>
  );
}
