import { useTranslation } from "next-i18next";
import clsx from "clsx";
import dynamic from "next/dynamic";
import { useRouter } from "next/router";
import { useRef } from "react";

import { useAdminSearchAPI, useSearchAPI } from "@/components/modals/SearchModal";

const SearchModal = dynamic(() => import("@/components/modals/SearchModal"));

export default function SearchInput() {
  const router = useRouter();
  const { t } = useTranslation("common");

  const inputRef = useRef<HTMLInputElement>(null);
  const { inputText: searchText, setInputText: setSearchText, searchResults } = useSearchAPI();
  const {
    inputText: searchAdminText,
    setInputText: setSearchAdminText,
    searchResults: searchAdminResults,
  } = useAdminSearchAPI();

  const isAdminPath = router.asPath.startsWith("/admin");

  return (
    <div
      className={clsx(
        "hidden lg:flex mx-6 xl:ml-10 h-12 px-4 w-44 xl:w-56",
        "items-center rounded-full border-2 border-purple-3 text-base",
        "leading-1.2 border-opacity-40 relative text-white"
      )}
    >
      <svg
        className="mr-3 flex-shrink-0"
        width="16"
        height="16"
        viewBox="0 0 17 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12.035 7.68426C12.035 10.4554 9.78861 12.7018 7.01751 12.7018C4.24642 12.7018 2 10.4554 2 7.68426C2 4.91317 4.24642 2.66675 7.01751 2.66675C9.78861 2.66675 12.035 4.91317 12.035 7.68426ZM11.025 13.4457C9.88878 14.2375 8.50736 14.7018 7.01751 14.7018C3.14185 14.7018 0 11.5599 0 7.68426C0 3.8086 3.14185 0.666748 7.01751 0.666748C10.8932 0.666748 14.035 3.8086 14.035 7.68426C14.035 9.34842 13.4558 10.8773 12.4879 12.0802L16.3268 15.9191L14.9126 17.3333L11.025 13.4457Z"
          className="fill-current"
        />
      </svg>
      <input
        ref={inputRef}
        key={isAdminPath ? "admin-search-input" : "search-input"}
        className="bg-transparent max-w-4/5 outline-none text-white"
        placeholder={t("common.search.placeholder", { context: isAdminPath ? "admin" : undefined })}
        value={isAdminPath ? searchAdminText : searchText}
        onChange={(e) => (isAdminPath ? setSearchAdminText(e.target.value) : setSearchText(e.target.value))}
        autoComplete="off"
        autoCorrect="off"
      />
      {(searchText || searchAdminText) && (
        <SearchModal
          searchText={isAdminPath ? searchAdminText : searchText}
          searchResults={isAdminPath ? searchAdminResults : searchResults}
          closeModal={(e) => {
            // Don't reset search when clicking on input
            if (!inputRef.current?.contains(e.target as Node)) {
              setSearchText("");
              setSearchAdminText("");
              searchResults.reset();
              searchAdminResults.reset();
            }
          }}
        />
      )}
    </div>
  );
}
