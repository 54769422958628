import { PropsWithChildren } from "react";
import { useTranslation } from "react-i18next";
import clsx from "clsx";
import Image from "next/image";
import { UseAsyncReturn } from "react-async-hook";
import { Trans } from "next-i18next";
import { motion } from "framer-motion";

import { initializeApollo } from "@/apollo";
import OutsideAlerter from "@/components/hoc/OutsideAlerter";
import {
  ReleaseState,
  SearchArtistFragment,
  SearchIndex,
  SearchIndexesDocument,
  SearchLabelFragment,
  SearchPlaylistFragment,
  SearchTrackAdminFragment,
  SearchTrackFragment,
  SearchTrackIndexAdminDocument,
  SearchUserFragment,
} from "@/graphql/types";
import { useDebouncedSearch } from "@/utils/hooks";
import Link from "@/components/hoc/CustomLink";
import LoadingIndicator from "@/components/ui/svg/LoadingIndicator";
import CloseButton from "@/components/ui/svg/CloseButton";
import partition from "@/utils/partition";
import SearchResult from "@/components/SearchResult";

type CommonSearchResults = {
  artists: SearchArtistFragment[];
  labels: SearchLabelFragment[];
  tracks: SearchTrackFragment[];
  users: SearchUserFragment[];
  playlists: SearchPlaylistFragment[];
};
type AdminSearchResults = {
  drafts: SearchTrackAdminFragment[];
  minted: SearchTrackAdminFragment[];
};
type Results = CommonSearchResults | AdminSearchResults;

type Props = {
  searchText: string;
  searchResults:
    | UseAsyncReturn<
        void | CommonSearchResults,
        (string | ((query: string) => Promise<CommonSearchResults>))[]
      >
    | UseAsyncReturn<
        void | AdminSearchResults,
        (string | ((query: string) => Promise<AdminSearchResults>))[]
      >;
  closeModal: (e: MouseEvent) => void;
};

type SearchProps = {
  indexes?: SearchIndex[];
};

function isAdminResult(result: Results): result is AdminSearchResults {
  return "drafts" in result;
}

export const useSearchAPI = (params?: SearchProps) =>
  useDebouncedSearch<CommonSearchResults>(async (text) => {
    const client = initializeApollo();

    const query = await client.query({
      query: SearchIndexesDocument,
      variables: {
        query: text,
        limit: 10,
        indexes: params?.indexes,
        filters: { userIndex: { artistId: { eq: null }, labelId: { eq: null } } },
      },
    });
    const results: CommonSearchResults = {
      artists: [],
      labels: [],
      tracks: [],
      users: [],
      playlists: [],
    };

    query.data.searchIndexes.forEach((index) => {
      switch (index.__typename) {
        case "SearchArtist":
          results.artists.push(index);
          break;
        case "SearchLabel":
          results.labels.push(index);
          break;
        case "SearchTrack":
          results.tracks.push(index);
          break;
        case "SearchUser":
          results.users.push(index);
          break;
        case "SearchPlaylist":
          results.playlists.push(index);
          break;
        default:
          break;
      }
    });

    return results;
  }, 200);

export const useAdminSearchAPI = () =>
  useDebouncedSearch<AdminSearchResults>(async (text) => {
    const client = initializeApollo();

    const tracksQuery = await client.query({
      query: SearchTrackIndexAdminDocument,
      variables: {
        query: text,
        limit: 14,
        filter: { releases: { buildState: { neq: null } } },
      },
    });

    const [drafts, minted] = partition(tracksQuery.data.searchTrackIndex, (track) =>
      track.releases.some((release) => release.buildState === ReleaseState.Draft)
    );

    return {
      drafts,
      minted,
    };
  }, 200);

export default function SearchModal({ searchResults, searchText, closeModal }: Props) {
  const { t } = useTranslation("common");

  const resultCount = searchResults.result
    ? Object.values(searchResults.result).reduce((acc, val) => acc + (val?.length ?? 0), 0)
    : undefined;

  return (
    <OutsideAlerter
      className="absolute top-14 left-1/2 transform -translate-x-1/2 h-max z-110"
      callback={closeModal}
    >
      <motion.svg
        key="search-tooltip"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        transition={{ ease: "easeOut", duration: 0.2, delay: 0.2 }}
        xmlns="http://www.w3.org/2000/svg"
        width="30"
        height="17"
        viewBox="0 0 30 17"
        fill="none"
        className="absolute left-1/2 top-2.5 transform -translate-x-1/2 -translate-y-full rotate-180"
      >
        <path
          d="M17.5594 15.3746C15.9973 16.3666 14.0027 16.3666 12.4406 15.3746L2.88186 9.30395C-1.15181 6.74222 0.662839 0.499999 5.44122 0.5L24.5588 0.500002C29.3372 0.500002 31.1518 6.74222 27.1181 9.30394L17.5594 15.3746Z"
          className="fill-dark-6"
        />
      </motion.svg>
      <motion.div
        key="search-container"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        className={clsx(
          "relative w-screen lg:w-114 max-w-114",
          "bg-dark-3/70 bg-dark-glass backdrop-blur-lg",
          "before:border-2 before:bg-dark-4 before:bg-dark-stroke",
          "before:border-gradient before:rounded-t-lg lg:before:rounded-lg",
          "max-h-screen-4/5 lg:rounded-lg overflow-hidden shadow-large",
          "flex flex-col ease-quart-out duration-300 transition"
        )}
      >
        <div
          onClick={(e) => {
            e.stopPropagation();
            closeModal(e.nativeEvent);
          }}
          className={clsx(
            "absolute right-4 top-4 flex border border-purple-4/40 w-6",
            "lg:w-8 h-6 lg:h-8 items-center justify-center rounded-full",
            "transition ease-quart-out duration-300 cursor-pointer",
            "md:hover:border-purple-4"
          )}
        >
          <CloseButton width="45%" height="45%" />
        </div>
        <div className={clsx("flex flex-col items-center justify-center px-4 lg:px-5", "py-5.5 lg:py-7")}>
          <div className="text-center text-sm lg:text-base leading-1.2 lg:leading-1.2 text-white/70">
            {searchResults.status !== "loading" && resultCount !== undefined ? (
              <Trans
                i18nKey="common.search.result"
                ns="common"
                count={resultCount}
                values={{ search: searchText }}
              >
                text
                <span className="text-purple-2 font-medium block" />
              </Trans>
            ) : (
              t("common.search.searching")
            )}
          </div>
          {searchResults.status === "loading" && (
            <div className="flex justify-center min-w-10 mt-1 text-purple">
              <LoadingIndicator height="20px" width="20px" />
            </div>
          )}
        </div>
        {searchResults.status === "success" && searchResults.result && !!resultCount && (
          <div className={"flex flex-col px-5 lg:px-6 mb-5 lg:mb-6 overflow-y-auto"}>
            {!isAdminResult(searchResults.result) && searchResults.result.artists?.length !== 0 && (
              <ResultSection
                title={t("common.counts.artist", { count: searchResults.result.artists.length })}
                closeModal={closeModal}
              >
                {searchResults.result.artists.map((artist) => (
                  <ResultCard artist={artist} key={"artist-" + artist.id} />
                ))}
              </ResultSection>
            )}
            {!isAdminResult(searchResults.result) && searchResults.result.labels?.length !== 0 && (
              <ResultSection
                title={t("common.counts.label", { count: searchResults.result.labels.length })}
                closeModal={closeModal}
              >
                {searchResults.result.labels.map((label) => (
                  <ResultCard label={label} key={"label-" + label.id} />
                ))}
              </ResultSection>
            )}
            {!isAdminResult(searchResults.result) && searchResults.result.tracks?.length !== 0 && (
              <ResultSection
                title={t("common.counts.song", { count: searchResults.result.tracks.length })}
                closeModal={closeModal}
              >
                {searchResults.result.tracks.map((track) => (
                  <ResultCard track={track} key={"track-" + track.id} />
                ))}
              </ResultSection>
            )}
            {!isAdminResult(searchResults.result) && searchResults.result.playlists?.length !== 0 && (
              <ResultSection
                title={t("common.counts.playlist", { count: searchResults.result.playlists.length })}
                closeModal={closeModal}
              >
                {searchResults.result.playlists.map((playlist) => (
                  <ResultCard playlist={playlist} key={"playlist-" + playlist.id} />
                ))}
              </ResultSection>
            )}
            {!isAdminResult(searchResults.result) && searchResults.result.users?.length !== 0 && (
              <ResultSection
                title={t("common.counts.user", { count: searchResults.result.users.length })}
                alwaysDoubleColumn
                closeModal={closeModal}
              >
                {searchResults.result.users.map((user) => (
                  <Link href={"/user/" + user.slug} key={"user-" + user.id}>
                    <a className="flex items-center min-w-0 group">
                      <div className="flex-shrink-0">
                        <Image
                          className="bg-purple-5 rounded-full"
                          alt=""
                          src={user.avatarUrl}
                          width="32"
                          height="32"
                        />
                      </div>
                      <span
                        className={clsx(
                          "ml-3 font-medium text-base leading-1.1 truncate",
                          "group-hover:text-purple-2"
                        )}
                      >
                        {user.displayName}
                      </span>
                    </a>
                  </Link>
                ))}
              </ResultSection>
            )}

            {isAdminResult(searchResults.result) && searchResults.result.drafts?.length !== 0 && (
              <ResultSection
                title={t("common.counts.draft", { count: searchResults.result.drafts.length })}
                closeModal={closeModal}
              >
                {searchResults.result.drafts.map((builder) => (
                  <AdminResultCard track={builder} key={"draft-" + builder.id} />
                ))}
              </ResultSection>
            )}
            {isAdminResult(searchResults.result) && searchResults.result.minted?.length !== 0 && (
              <ResultSection
                title={t("common.counts.minted", { count: searchResults.result.minted.length })}
                closeModal={closeModal}
              >
                {searchResults.result.minted.map((builder, i: number) => (
                  <AdminResultCard track={builder} key={"minted-" + i} />
                ))}
              </ResultSection>
            )}
          </div>
        )}
      </motion.div>
    </OutsideAlerter>
  );
}

type SectionProps = {
  title: string;
  closeModal: (e: MouseEvent) => void;
  alwaysDoubleColumn?: boolean;
};

function ResultSection({ title, closeModal, alwaysDoubleColumn, children }: PropsWithChildren<SectionProps>) {
  return (
    <div className="mb-5 lg:mb-6 last:mb-0">
      <div className="flex items-center justify-between mb-6">
        <div
          className={clsx(
            "uppercase text-xs leading-1 lg:text-sm lg:leading-1",
            "tracking-wider flex-shrink-0 mr-3 text-white/70"
          )}
        >
          {title}
        </div>
        <div className="h-[1px] bg-dark-stroke grow" />
      </div>
      <div
        onClick={(e) => closeModal(e.nativeEvent)}
        className={clsx(
          "grid gap-3 w-full rounded-md overflow-hidden",
          alwaysDoubleColumn ? "grid-cols-2" : "grid-cols-1 lg:grid-cols-2"
        )}
      >
        {children}
      </div>
    </div>
  );
}

type ResultCardProps = {
  track?: SearchTrackFragment;
  artist?: SearchArtistFragment;
  label?: SearchLabelFragment;
  playlist?: SearchPlaylistFragment;
};

function ResultCard({ track, artist, label, playlist }: ResultCardProps) {
  let link = label
    ? `/user/${label.owner.slug}`
    : `/${track?.artists[0]?.slug ?? artist?.slug}/${track?.slug ?? ""}`;
  if (playlist) link = `/playlists/${playlist.slug}`;
  return (
    <Link href={link}>
      <a>
        <SearchResult track={track} artist={artist} label={label} playlist={playlist} />
      </a>
    </Link>
  );
}

type AdminResultCardProps = {
  track: SearchTrackAdminFragment;
};

function AdminResultCard({ track }: AdminResultCardProps) {
  return (
    <Link href={`/admin/mint/${track.id}`}>
      <a
        className={clsx(
          "w-full box-border border border-purple-5",
          "md:hover:border-transparent md:hover:bg-gradient-to-tl",
          "from-purple-6 to-purple-6-opacity-50 rounded-md transition",
          "ease-quart-out duration-300 h-16 lg:h-20 px-3 lg:px-4 flex",
          "items-center cursor-pointer overflow-hidden relative"
        )}
      >
        <div className="flex-shrink-0">
          <Image
            className={"rounded"}
            alt=""
            src={track.thumbnailMinifiedImageUrl ?? "/assets/misc/no-image.svg"}
            width="48"
            height="48"
          />
        </div>
        <div className="flex flex-col ml-3 lg:ml-4 min-w-0">
          <div className="text-xs leading-1.2 uppercase mb-1 truncate">{track.artists[0]?.name}</div>
          <div className="text-sm lg:text-base font-medium leading-1.1 lg:leading-1.1 line-clamp-2">
            {track.title}
          </div>
        </div>
      </a>
    </Link>
  );
}
