import { useTranslation } from "next-i18next";
import { useContext, useState } from "react";
import { useRouter } from "next/router";
import { motion } from "framer-motion";
import clsx from "clsx";

import { getLeaderboardNameKey } from "@/utils/notifications";
import UserLabelInvitationToast from "@/components/notifications/toasts/UserLabelInvitation";
import { markNotificationsAsReadUpdate } from "@/apollo/cache/updates/notifications";
import { NotificationsContext } from "@/utils/reactContexts";
import { GetNotificationsQuery, NotificationType } from "@/graphql/types";
import { useMarkNotificationsAsReadMutation } from "@/utils/mutationHooks";
import NotificationCard from "@/components/notifications/NotificationCard";
import LoadingIndicator from "@/components/ui/svg/LoadingIndicator";
import { getCurrencyFormatter } from "@/utils/currencies";
import CloseButton from "@/components/ui/svg/CloseButton";
import { getNumberFormatter } from "@/utils/numbers";
import { getScarcityKey } from "@/utils/scarcity";
import { NO_IMAGE_URL } from "@/utils/constants";
import { useWallet } from "@/components/Wallet";
import localisedReportReason from "@/utils/reportReason";
import CollabInvitationToast from "@/components/notifications/toasts/CollabInvitation";

type Notification = GetNotificationsQuery["notifications"]["results"][0];
type Props = {
  notification: Notification;
  contentOnly?: boolean;
  layoutId?: string;
  disableDrawerClose?: boolean;
};

export default function Notification({ notification, contentOnly, layoutId, disableDrawerClose }: Props) {
  const router = useRouter();
  const { t } = useTranslation(["notifications", "common"]);

  const formatter = getCurrencyFormatter().format;

  const { setShowWallet } = useWallet();
  const { setShowNotifications } = useContext(NotificationsContext);

  const [processing, setProcessing] = useState(false);
  const [markAsReadMutation] = useMarkNotificationsAsReadMutation();

  async function clearNotifications(notificationIds: string[]) {
    setProcessing(true);
    await markAsReadMutation({
      variables: { notificationIds },
      update: markNotificationsAsReadUpdate,
    });
    setProcessing(false);
  }

  let card: JSX.Element | null = null;

  const me = notification.notifiedUser;
  switch (notification.__typename) {
    case "ArtistNotification":
      switch (notification.type) {
        case NotificationType.ArtistProfileCreated:
          card = (
            <NotificationCard
              disableDrawerClose={disableDrawerClose}
              tag="account"
              dateString={notification.createdAt}
              link={`/artist/${notification.artist.slug}`}
            >
              {t("notifications:notifications.messages.account.artist.created")}
            </NotificationCard>
          );
          break;

        case NotificationType.ArtistProfileVerified:
          card = (
            <NotificationCard
              disableDrawerClose={disableDrawerClose}
              tag="account"
              dateString={notification.createdAt}
              link={`/artist/${notification.artist.slug}`}
            >
              {t("notifications:notifications.messages.account.artist.verified")}
            </NotificationCard>
          );
          break;

        case NotificationType.ArtistProfileDeclined:
          card = (
            <NotificationCard
              disableDrawerClose={disableDrawerClose}
              tag="account"
              level="important"
              dateString={notification.createdAt}
              link={"/user/" + me.slug}
            >
              {t("notifications:notifications.messages.account.artist.denied")}
            </NotificationCard>
          );
          break;

        case NotificationType.LabelInvitationAccepted:
          card = (
            <NotificationCard
              disableDrawerClose={disableDrawerClose}
              tag="label"
              dateString={notification.createdAt}
              link={`/artist/${notification.artist.slug}`}
              imageUrl={notification.artist.user?.avatarUrl ?? NO_IMAGE_URL}
            >
              {t("notifications:notifications.messages.label.invites.accepted", {
                artist: notification.artist.name,
              })}
            </NotificationCard>
          );
          break;

        case NotificationType.LabelInvitationDeclined:
          card = (
            <NotificationCard
              disableDrawerClose={disableDrawerClose}
              tag="label"
              level="important"
              dateString={notification.createdAt}
              link={
                me.label ? `/labels/${me.label.slug}?section=artists` : `/artist/${notification.artist.slug}`
              }
              imageUrl={notification.artist.user?.avatarUrl ?? NO_IMAGE_URL}
            >
              {t("notifications:notifications.messages.label.invites.declined", {
                artist: notification.artist.name,
              })}
            </NotificationCard>
          );
          break;

        default:
          break;
      }
      break;

    case "LabelNotification": {
      const { label } = notification;
      switch (notification.type) {
        case NotificationType.LabelUserInvitation:
          card = (
            <NotificationCard
              disableDrawerClose={disableDrawerClose}
              tag="label"
              imageUrl={label.owner.avatarUrl}
              dateString={notification.createdAt}
              link={`/artists/register?invitation=true&labelId=${label.id}`}
            >
              <UserLabelInvitationToast {...{ label, notificationId: notification.id }} />
            </NotificationCard>
          );
          break;

        default:
          break;
      }
      break;
    }

    case "LeaderboardNotification": {
      const entry = notification.leaderboardEntry;
      const leaderboard = t(`common:common.leaderboards.${getLeaderboardNameKey(entry.leaderboard)}`);
      switch (notification.type) {
        case NotificationType.LeaderboardWon:
          card = (
            <NotificationCard
              disableDrawerClose={disableDrawerClose}
              tag="leaderboard"
              dateString={notification.createdAt}
            >
              {t("notifications:notifications.messages.account.rewards.leaderboard", {
                amount: notification.piaMove.amount,
                rank: entry.rank,
                leaderboard,
              })}
            </NotificationCard>
          );
          break;

        default:
          break;
      }
      break;
    }

    case "NFTUserNotification": {
      const userNft = notification.nft;
      switch (notification.type) {
        case NotificationType.GiveawayClaimed:
          card = (
            <NotificationCard
              disableDrawerClose={disableDrawerClose}
              tag="bounty"
              level="info"
              imageUrl={userNft.release.track.thumbnail?.minifiedImageUrl}
              dateString={notification.createdAt}
              link={`/${userNft.release.track.artists[0]?.slug}/${userNft.release.track.slug}`}
            >
              {t("notifications:notifications.messages.market.artist.claimed", {
                number: userNft.number,
                rarity: t(`common:common.rarity.${getScarcityKey(userNft.release.rarity)}`),
                track: userNft.release.track.title,
                username: notification.targetUser.displayName,
              })}
            </NotificationCard>
          );
          break;

        default:
          break;
      }
      break;
    }

    case "PaymentNotification": {
      const { payment } = notification;
      if (!payment) {
        card = null;
        break;
      }
      const { track } = payment.sale.nft.release;
      const bidLink = `/${track.artists[0]?.slug}/${track.slug}?bid=${payment.sale.nft.id}`;
      switch (notification.type) {
        case NotificationType.AuctionOutbid:
          card = (
            <NotificationCard
              disableDrawerClose={disableDrawerClose}
              tag="outbid"
              level="important"
              imageUrl={payment.sale.nft.release.track.thumbnail?.minifiedImageUrl}
              dateString={notification.createdAt}
              link={bidLink}
            >
              <div>
                {t("notifications:notifications.messages.market.collector.outbid", {
                  username: payment.user.displayName,
                  track: payment.sale.nft.release.track.title,
                  price: formatter(payment.amount.amount),
                })}
                <button
                  onClick={
                    disableDrawerClose
                      ? undefined
                      : () => {
                          router.push(bidLink, undefined, { shallow: true });
                          setShowNotifications(false);
                        }
                  }
                  className={clsx(
                    "block mt-3 text-orange text-xs leading-1 font-medium",
                    "uppercase tracking-wider"
                  )}
                >
                  {t("notifications:notifications.messages.market.collector.outbid-cta")}
                </button>
              </div>
            </NotificationCard>
          );
          break;

        case NotificationType.AuctionWon: {
          const shareLink =
            (me.artist ? `/artist/${me.artist.slug}` : `/user/${me.slug}`) +
            "?section=collection&share=" +
            payment.sale.nft.id;
          card = (
            <NotificationCard
              disableDrawerClose={disableDrawerClose}
              tag="buy"
              imageUrl={payment.sale.nft.release.track.thumbnail?.minifiedImageUrl}
              dateString={notification.createdAt}
              link={shareLink}
            >
              <div>
                {t("notifications:notifications.messages.market.collector.won", {
                  number: payment.sale.nft.number,
                  rarity: t(`common:common.rarity.${getScarcityKey(payment.sale.nft.release.rarity)}`),
                  track: payment.sale.nft.release.track.title,
                  artist: payment.sale.nft.release.track.artists[0]?.name,
                })}
                <button
                  onClick={
                    disableDrawerClose
                      ? undefined
                      : () => {
                          router.push(shareLink, undefined, { shallow: true });
                          setShowNotifications(false);
                        }
                  }
                  className={clsx(
                    "block mt-3 text-purple text-xs leading-1 font-medium",
                    "uppercase tracking-wider"
                  )}
                >
                  {t("common:common.buttons.share_nft")}
                </button>
              </div>
            </NotificationCard>
          );
          break;
        }

        case NotificationType.OwnNftSold: {
          const { track } = payment.sale.nft.release;
          card = (
            <NotificationCard
              disableDrawerClose={disableDrawerClose}
              tag="sold"
              imageUrl={track.thumbnail?.minifiedImageUrl}
              dateString={notification.createdAt}
              link={`/${track.artists[0]?.slug}/${track.slug}`}
            >
              {t("notifications:notifications.messages.market.artist.sold", {
                username: payment.user.displayName,
                number: payment.sale.nft.number,
                rarity: t(`common:common.rarity.${getScarcityKey(payment.sale.nft.release.rarity)}`),
                track: track.title,
                artist: track.artists[0]?.name,
                price: formatter(payment.amount.amount),
              })}
            </NotificationCard>
          );
          break;
        }

        case NotificationType.OwnNftBidPlaced: {
          const { track } = payment.sale.nft.release;
          card = (
            <NotificationCard
              disableDrawerClose={disableDrawerClose}
              tag="bid"
              imageUrl={track.thumbnail?.minifiedImageUrl}
              dateString={notification.createdAt}
              link={`/${track.artists[0]?.slug}/${track.slug}`}
            >
              {t("notifications:notifications.messages.market.artist.bid", {
                amount: formatter(payment.amount.amount),
                rarity: t(`common:common.rarity.${getScarcityKey(payment.sale.nft.release.rarity)}`),
                number: payment.sale.nft.number,
                track: track.title,
                artist: track.artists[0]?.name,
                bidder: payment.user.displayName,
              })}
            </NotificationCard>
          );
          break;
        }

        default:
          break;
      }
      break;
    }

    case "TrackNotification": {
      const { track } = notification;
      switch (notification.type) {
        case NotificationType.ArtistTrackReleased:
          card = (
            <NotificationCard
              disableDrawerClose={disableDrawerClose}
              tag="market"
              imageUrl={track.thumbnail?.minifiedImageUrl}
              dateString={notification.createdAt}
              link={`/${track.artists[0]?.slug}/${track.slug}`}
            >
              {t("notifications:notifications.messages.market.collector.announced", {
                track: track.title,
                artist: track.artists[0]?.name,
              })}
            </NotificationCard>
          );
          break;

        default:
          break;
      }
      break;
    }

    case "UserNotification": {
      const user = notification.targetUser;
      switch (notification.type) {
        case NotificationType.Followed:
          card = (
            <NotificationCard
              disableDrawerClose={disableDrawerClose}
              imageUrl={user.avatarUrl}
              dateString={notification.createdAt}
              link={"/user/" + user.slug}
            >
              {t("notifications:notifications.messages.account.artist.followed", {
                username: user.displayName,
              })}
            </NotificationCard>
          );
          break;

        case NotificationType.NewReferral:
          card = (
            <NotificationCard
              disableDrawerClose={disableDrawerClose}
              tag="referral"
              imageUrl={user.avatarUrl}
              dateString={notification.createdAt}
              link={"/profile/settings"}
            >
              {t("notifications:notifications.messages.account.referral.new", {
                username: user.displayName,
              })}
            </NotificationCard>
          );
          break;

        case NotificationType.ReferralConfirmed:
          card = (
            <NotificationCard
              disableDrawerClose={disableDrawerClose}
              tag="referral"
              imageUrl={user.avatarUrl}
              dateString={notification.createdAt}
              // link={"/leaderboards"}
            >
              {t("notifications:notifications.messages.account.referral.confirmed", {
                username: user.displayName,
              })}
            </NotificationCard>
          );
          break;

        case NotificationType.LabelInvitationDeclined:
          card = (
            <NotificationCard
              disableDrawerClose={disableDrawerClose}
              tag="label"
              level="important"
              dateString={notification.createdAt}
              link={me.label ? `/labels/${me.label.slug}?section=artists` : `/user/${user.slug}`}
              imageUrl={user.avatarUrl}
            >
              {t("notifications:notifications.messages.label.invites.declined", { artist: user.username })}
            </NotificationCard>
          );
          break;

        default:
          break;
      }
      break;
    }

    case "WalletNotification": {
      const event = notification.eventWallet;
      switch (notification.type) {
        case NotificationType.WalletDeposit:
          if (event.__typename === "CoinbasePayment") {
            card = (
              <NotificationCard
                disableDrawerClose={disableDrawerClose}
                tag="deposit"
                dateString={notification.createdAt}
                onClick={disableDrawerClose ? undefined : () => setShowWallet("history")}
              >
                {t("notifications:notifications.messages.account.wallet.deposit", {
                  amount: formatter(event.localPrice.amount),
                })}
              </NotificationCard>
            );
          }
          break;

        case NotificationType.RewardReceived:
          if (event.__typename === "Reward") {
            card = (
              <NotificationCard
                disableDrawerClose={disableDrawerClose}
                tag="reward"
                dateString={notification.createdAt}
                onClick={disableDrawerClose ? undefined : () => setShowWallet("history")}
              >
                {t("notifications:notifications.messages.account.rewards.weekly_fiat", {
                  amount: formatter(event.amount.amount),
                  apy: event.apy,
                })}
              </NotificationCard>
            );
          }
          break;

        case NotificationType.RewardPiaReceived:
          if (event.__typename === "PiaMove") {
            card = (
              <NotificationCard
                disableDrawerClose={disableDrawerClose}
                tag="reward"
                dateString={notification.createdAt}
                onClick={disableDrawerClose ? undefined : () => setShowWallet("history")}
              >
                {t("notifications:notifications.messages.account.rewards.weekly_pia", {
                  amount: getNumberFormatter(undefined, {
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 2,
                  }).format(event.tokenAmount),
                })}
              </NotificationCard>
            );
          }
          break;

        case NotificationType.WithdrawRequestAccepted:
          if (event.__typename === "Withdraw") {
            card = (
              <NotificationCard
                disableDrawerClose={disableDrawerClose}
                tag="withdraw"
                dateString={notification.createdAt}
                onClick={disableDrawerClose ? undefined : () => setShowWallet("history")}
              >
                {t("notifications:notifications.messages.account.wallet.withdrawal.request", {
                  amount: getCurrencyFormatter(event.originalCurrency).format(event.originalAmount),
                })}
              </NotificationCard>
            );
          }
          break;

        case NotificationType.WithdrawRequestDenied:
          if (event.__typename === "Withdraw") {
            card = (
              <NotificationCard
                disableDrawerClose={disableDrawerClose}
                tag="withdraw"
                level="error"
                dateString={notification.createdAt}
                onClick={disableDrawerClose ? undefined : () => setShowWallet("history")}
              >
                {t("notifications:notifications.messages.account.wallet.withdrawal.failed", {
                  amount: getCurrencyFormatter(event.originalCurrency).format(event.originalAmount),
                })}
              </NotificationCard>
            );
          }
          break;

        default:
          break;
      }
      break;
    }

    case "TrackArtistNotification": {
      const { track } = notification;
      let collabNotifMessage: React.ReactNode | undefined;
      switch (notification.type) {
        case NotificationType.InvitedToCollab:
          if (track) {
            collabNotifMessage = <CollabInvitationToast track={track} notificationId={notification.id} />;
          }
          break;

        default:
          break;
      }

      if (collabNotifMessage) {
        card = (
          <NotificationCard
            disableDrawerClose={disableDrawerClose}
            tag="collab"
            imageUrl={track?.thumbnail?.minifiedImageUrl ?? "/assets/misc/no-image.svg"}
            dateString={notification.createdAt}
            link={track ? `/${track.artists[0].slug}/${track.slug}` : undefined}
          >
            {collabNotifMessage}
          </NotificationCard>
        );
      }
      break;
    }

    case "TrackReportNotification": {
      const reportedTrack = notification.track;
      switch (notification.type) {
        case NotificationType.TrackRemoved:
          card = (
            <NotificationCard
              disableDrawerClose={disableDrawerClose}
              tag="moderation"
              level="error"
              imageUrl={reportedTrack?.thumbnail?.minifiedImageUrl ?? "/assets/misc/no-image.svg"}
              dateString={notification.createdAt}
              link="https://help.pianity.com/hc/en-us/articles/5056063988381-Artists-Terms-Conditions"
            >
              {t("notifications:notifications.messages.waitlist.artist.removed", {
                track: reportedTrack?.title ?? "---",
                reason: localisedReportReason(notification.reportReason.reason),
              })}
            </NotificationCard>
          );
          break;

        case NotificationType.TrackReportAccepted:
          card = (
            <NotificationCard
              disableDrawerClose={disableDrawerClose}
              tag="moderation"
              imageUrl={reportedTrack?.thumbnail?.minifiedImageUrl ?? "/assets/misc/no-image.svg"}
              dateString={notification.createdAt}
              // link="/leaderboards"
            >
              {t("notifications:notifications.messages.waitlist.collector.removed", {
                track: reportedTrack?.title ?? "---",
                reason: localisedReportReason(notification.reportReason.reason),
              })}
            </NotificationCard>
          );
          break;

        case NotificationType.TrackReportIgnored:
          card = (
            <NotificationCard
              disableDrawerClose={disableDrawerClose}
              tag="moderation"
              level="important"
              imageUrl={reportedTrack?.thumbnail?.minifiedImageUrl ?? "/assets/misc/no-image.svg"}
              dateString={notification.createdAt}
              // link="/leaderboards"
            >
              {t("notifications:notifications.messages.waitlist.collector.ignored", {
                track: reportedTrack?.title ?? "---",
                reason: localisedReportReason(notification.reportReason.reason),
              })}
            </NotificationCard>
          );
          break;

        default:
          break;
      }
      break;
    }

    default:
      break;
  }

  if (!card) return null;
  if (contentOnly) return card;
  return (
    <motion.div
      layout={layoutId ? undefined : "position"}
      layoutId={layoutId}
      className={clsx(
        "rounded-md text-sm leading-1.2 p-3 flex justify-between w-full",
        "bg-dark-4 shadow-medium relative border border-purple-2",
        "bg-gradient-to-r from-purple/30 to-purple/30"
      )}
    >
      {card}
      {processing ? (
        <div className={"w-5 h-5 flex justify-center items-center flex-shrink-0"}>
          <LoadingIndicator width="16" height="16" />
        </div>
      ) : (
        <button
          onClick={disableDrawerClose ? undefined : () => clearNotifications([notification.id])}
          className={clsx(
            "w-5 h-5 rounded-full shrink-0 text-dark-8",
            "bg-dark-stroke-clear relative flex justify-center items-center",
            "hover:bg-dark-hover hover:text-white",
            "before:border before:bg-dark-stroke before:bg-purple-4.5/10",
            "before:border-gradient before:rounded-full",
            processing && "pointer-events-none opacity-70"
          )}
        >
          <CloseButton width={10} height={10} />
        </button>
      )}
    </motion.div>
  );
}
