import { LayoutGroup, motion } from "framer-motion";
import { useTranslation } from "next-i18next";
import clsx from "clsx";
import { useState } from "react";

import { markNotificationsAsReadUpdate } from "@/apollo/cache/updates/notifications";
import { useMarkNotificationsAsReadMutation } from "@/utils/mutationHooks";
import Notification from "@/components/notifications/Notification";
import { notificationTypeToTagKey } from "@/utils/notifications";
import ClearButton from "@/components/notifications/ClearButton";
import { GetNotificationsQuery } from "@/graphql/types";
import Chevron from "@/components/ui/svg/Chevron";

type Notification = GetNotificationsQuery["notifications"]["results"][0];
type Props = {
  notifications: Notification[];
  stackId: string;
};

export default function NotificationStack({ notifications, stackId }: Props) {
  const { t } = useTranslation(["notifications", "common"]);
  const latest = notifications[0];

  const [show, setShow] = useState(false);
  const [processing, setProcessing] = useState(false);

  const [markAsReadMutation] = useMarkNotificationsAsReadMutation();

  async function clearNotifications(notificationIds: string[]) {
    setProcessing(true);
    await markAsReadMutation({
      variables: { notificationIds },
      update: markNotificationsAsReadUpdate,
    });
    setProcessing(false);
  }

  if (!latest) return null;

  const hiddenCount = notifications.length - 1;
  const latestCard = (
    <Notification
      notification={latest}
      key={String(latest?.__typename) + 0}
      disableDrawerClose={!show && hiddenCount > 0}
    />
  );

  const latestTagKey = notificationTypeToTagKey(latest);
  return (
    <LayoutGroup id={stackId}>
      <div
        onClick={hiddenCount > 0 ? () => setShow(true) : undefined}
        className={"flex flex-col items-center" + (!show && hiddenCount > 0 ? " cursor-pointer" : "")}
      >
        {show && (
          <motion.div layout="position" className={"flex w-full items-center justify-between mb-4 lg:mb-5"}>
            <div className="text-14 leading-0.75 text-purple-4 tracking-wider uppercase">
              {`${notifications.length} ${
                latestTagKey
                  ? t(`notifications:${latestTagKey}`)
                  : t("common:common.counts.notification", { count: notifications.length })
              }`}
            </div>
            <div className="flex items-center">
              <button
                onClick={(e) => {
                  e.stopPropagation();
                  setShow(false);
                }}
                className={clsx(
                  "mr-1.5 px-2 h-5 text-10 uppercase flex items-center",
                  "justify-center gap-1 bg-purple bg-opacity-70 text-white",
                  "rounded-full cursor-pointer"
                )}
              >
                {t("notifications:notifications.collapse")}
                <Chevron width="7" height="5.25" />
              </button>
              <ClearButton
                onClick={() => clearNotifications(notifications.map((n) => n.id))}
                disabled={processing}
              />
            </div>
          </motion.div>
        )}
        {latestCard}
        {!show && hiddenCount > 0 && (
          <motion.div
            layoutId={latest.type + latest.category + "-fake-card-0"}
            className="bg-dark-7/25 shadow-medium w-9.5/10 h-2 -z-5 rounded-b-md backdrop-blur-2xl"
          />
        )}
        {!show && hiddenCount > 1 && (
          <motion.div
            layoutId={latest.type + latest.category + "-fake-card-1"}
            className="bg-dark-7/15 shadow-medium w-9/10 h-1.5 -z-10 rounded-b-md backdrop-blur-2xl"
          />
        )}
        {!show &&
          hiddenCount > 2 &&
          Array.from(Array(Math.min(hiddenCount - 2, 10)), (_, i) => i + 1).map((_, i) => (
            <motion.div
              key={"fake-card-" + i}
              layoutId={latest.type + latest.category + "-fake-card-" + (i + 2)}
              className="bg-dark-7/15 shadow-medium w-9/10 h-0 -z-10 rounded-b-md backdrop-blur-2xl"
            />
          ))}
        {show && (
          <div className="flex flex-col gap-3 lg:gap-4 my-3 lg:my-4">
            {notifications.slice(1, 25).map((notification, i) => (
              <Notification
                notification={notification}
                layoutId={latest.type + latest.category + "-fake-card-" + i}
                key={String(notification.__typename) + i}
              />
            ))}
            {hiddenCount > 24 && (
              <motion.div layout="position" className="-mt-1 text-purple-5 text-sm ml-auto w-max">
                {t("notifications:notifications.more", { count: hiddenCount - 24 })}
              </motion.div>
            )}
          </div>
        )}
      </div>
    </LayoutGroup>
  );
}
