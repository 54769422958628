import { i18n } from "next-i18next";

export default function localisedReportReason(reason: string) {
  switch (reason) {
    case "CopyrightArtwork":
      return i18n?.t("common:common.reports.copyright_artwork");
    case "CopyrightAudio":
      return i18n?.t("common:common.reports.copyright_audio");
    case "InappropriateArtwork":
      return i18n?.t("common:common.reports.inappropriate_artwork");
    case "InappropriateAudio":
      return i18n?.t("common:common.reports.inappropriate_audio");
    case "TechnicalIssues":
      return i18n?.t("common:common.reports.technical");
    case "Other":
      return i18n?.t("common:common.reports.other");

    default:
      // Won't be localised, but at least it won't be undefined
      return reason;
  }
}
