import { GetNotificationsQuery, LeaderboardType, NotificationType } from "@/graphql/types";
import { exhaustiveSwitch } from "@/utils/exhaustiveSwitch";

type Notification = GetNotificationsQuery["notifications"]["results"][0];

export function groupNotifsBy(notification: Notification) {
  switch (notification.__typename) {
    case "ArtistNotification":
      return notification.artist.id;

    case "LeaderboardNotification":
      return notification.leaderboardEntry.leaderboard;

    case "NFTUserNotification":
      return notification.nft.release.id;

    case "PaymentNotification":
      if (!notification.payment) return null;

      switch (notification.type) {
        case NotificationType.AuctionOutbid:
        case NotificationType.AuctionWon:
        case NotificationType.OwnNftBidPlaced:
          return notification.payment.sale.nft.id;
        case NotificationType.OwnNftSold:
          return notification.payment.sale.nft.release.id;
        default:
          return null;
      }

    case "TrackNotification":
      return notification.track.id;

    case "UserNotification":
      return null;

    case "WalletNotification":
      return null;

    default:
      return null;
  }
}

export function notificationTypeToTagKey(notification: Notification) {
  switch (notification.__typename) {
    case "ArtistNotification":
      return "notifications.tags.account";

    case "LeaderboardNotification":
      return "notifications.tags.leaderboard";

    case "NFTUserNotification":
      switch (notification.type) {
        case NotificationType.GiveawayClaimed:
          return "notifications.tags.bounty";

        default:
          return undefined;
      }

    case "PaymentNotification":
      switch (notification.type) {
        case NotificationType.AuctionOutbid:
          return "notifications.tags.outbid";
        case NotificationType.AuctionWon:
          return "notifications.tags.bought";
        case NotificationType.OwnNftBidPlaced:
          return "notifications.tags.bid";
        case NotificationType.OwnNftSold:
          return "notifications.tags.sold";

        default:
          return undefined;
      }

    case "TrackNotification":
      return "notifications.tags.market";

    case "UserNotification":
      switch (notification.type) {
        case NotificationType.Followed:
          return "notifications.tags.account";
        case NotificationType.NewReferral:
        case NotificationType.ReferralConfirmed:
          return "notifications.tags.referral";

        default:
          return undefined;
      }

    case "TrackArtistNotification":
      if (
        [
          NotificationType.InvitedToCollab,
          NotificationType.CollaborationInvitationAccepted,
          NotificationType.CollaborationInvitationDeclined,
        ].includes(notification.type)
      ) {
        return "notifications.tags.collab";
      }
      return undefined;
    case "TrackReportNotification":
      return "notifications.tags.moderation";

    case "WalletNotification":
      switch (notification.type) {
        case NotificationType.RewardReceived:
        case NotificationType.RewardPiaReceived:
          return "notifications.tags.reward";
        case NotificationType.WalletDeposit:
          return "notifications.tags.deposit";
        case NotificationType.WithdrawRequestAccepted:
        case NotificationType.WithdrawRequestDenied:
          return "notifications.tags.withdraw";

        default:
          return undefined;
      }

    default:
      return undefined;
  }
}

export function getLeaderboardNameKey(leaderboard: LeaderboardType) {
  switch (leaderboard) {
    case LeaderboardType.Bids:
      return "bidders";
    case LeaderboardType.NftNumber:
      return "collectors_primary";
    case LeaderboardType.NftNumberSecondary:
      return "collectors_secondary";
    case LeaderboardType.NftPrice:
      return "buyers";
    case LeaderboardType.Referral:
      return "referrers";
    case LeaderboardType.TrackReports:
      return "moderators";
    case LeaderboardType.OldWaitlistCurators:
      return "curators";

    default:
      return exhaustiveSwitch(leaderboard);
  }
}

export function getLeaderboardRank(rank: number, locale: string | undefined) {
  if (rank === 1) return locale === "FR" ? "1er" : "1st";
  if (rank === 2) return locale === "FR" ? rank + "ème" : "2nd";
  if (rank === 3) return locale === "FR" ? rank + "ème" : "3rd";
  if (rank > 3) return locale === "FR" ? rank + "ème" : rank + "th";

  return undefined;
}
