import { useReactiveVar, useSubscription } from "@apollo/client";
import { useContext, useEffect, useRef } from "react";
import dynamic from "next/dynamic";

import { NotificationsContext } from "@/utils/reactContexts";
import { hasAccessTokenVar } from "@/apollo/links/auth";
import { showNotificationCard } from "@/utils/toasts";
import { getCurrencyCode } from "@/utils/currencies";
import { initializeApollo } from "@/apollo";
import {
  Currency,
  GetMyLabelDocument,
  GetNotificationsDocument,
  GetNotificationsQuery,
  MeDocument,
  NotificationType,
  OnNotificationAddedDocument,
} from "@/graphql/types";

const Notification = dynamic(() => import("@/components/notifications/Notification"));

function useNotificationsSubscription() {
  const isLogged = useReactiveVar(hasAccessTokenVar);
  const { showNotifications } = useContext(NotificationsContext);
  const showNotificationsRef = useRef(showNotifications);

  const { data: notifications } = useSubscription(OnNotificationAddedDocument, {
    fetchPolicy: "network-only",
    skip: !isLogged,
    variables: {
      currency: getCurrencyCode() as string as Currency,
    },
  });

  useEffect(() => {
    const variables = { currency: getCurrencyCode() as string as Currency };

    if (notifications?.notificationSubscription) {
      const client = initializeApollo();

      updateCacheOnNotif({ notification: notifications.notificationSubscription });

      client.cache.updateQuery({ query: MeDocument, variables }, (data) => {
        if (data) {
          return { me: { ...data.me, unreadNotificationsCount: data.me.unreadNotificationsCount + 1 } };
        }
        return data;
      });

      if (!showNotificationsRef.current) {
        showNotificationCard(
          <Notification notification={notifications.notificationSubscription} contentOnly />,
          { containerId: "notifications" }
        );
      }

      client.cache.updateQuery(
        { query: GetNotificationsDocument, variables: { ...variables, isRead: false } },
        (data) => {
          if (data) {
            return {
              me: data.me,
              notifications: {
                results: [notifications.notificationSubscription, ...data.notifications.results],
                size: data.notifications.size + 1,
              },
            };
          }
          return data;
        }
      );
    }
  }, [notifications]);
}

interface Params {
  notification: GetNotificationsQuery["notifications"]["results"][0];
}
function updateCacheOnNotif({ notification }: Params) {
  const client = initializeApollo();

  switch (notification.type) {
    case NotificationType.LabelInvitationAccepted:
    case NotificationType.LabelInvitationDeclined: {
      client.refetchQueries({ include: [GetMyLabelDocument] });
      break;
    }

    default:
      break;
  }
}

export default useNotificationsSubscription;
