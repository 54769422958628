export default function throttle<Params extends unknown[]>(cb: (...args: Params) => unknown, delay: number) {
  let isThrottled = false;
  return (...args: Params) => {
    if (isThrottled) return;
    isThrottled = true;
    cb(...args);
    setTimeout(() => {
      isThrottled = false;
    }, delay);
  };
}
