import { useState, useEffect, useCallback, useRef } from "react";
import AwesomeDebouncePromise from "awesome-debounce-promise";
import { useAsync } from "react-async-hook";
import { useRouter } from "next/router";
import useConstant from "use-constant";

import throttle from "@/utils/throttle";

/** Ask for confirmation before changing page or leaving site.
 *
 * @see https://git.io/JOskG
 */
export function useNavigationLock(
  isEnabled = true,
  warningText = "You have unsaved changes – are you sure you wish to leave this page?"
) {
  const router = useRouter();

  useEffect(() => {
    const handleWindowClose = (e: BeforeUnloadEvent) => {
      if (!isEnabled) return undefined;
      e.preventDefault();
      e.returnValue = warningText;
      return warningText;
    };

    const handleBrowseAway = (url: string, { shallow }: { shallow: boolean }) => {
      if (!isEnabled || shallow) return;
      if (window.confirm(warningText)) return;
      router.events.emit("routeChangeError");
      throw Error("Route change safely aborted. Please ignore.");
    };

    window.addEventListener("beforeunload", handleWindowClose);

    router.events.on("routeChangeStart", handleBrowseAway);

    return () => {
      window.removeEventListener("beforeunload", handleWindowClose);
      router.events.off("routeChangeStart", handleBrowseAway);
    };
  }, [isEnabled, warningText, router.events]);
}

export const useDebouncedSearch = <T>(searchFunction: (query: string) => Promise<T>, wait = 300) => {
  // Handle the input text state
  const [inputText, setInputText] = useState("");

  // Debounce the original search async function
  const debouncedSearchFunction = useConstant(() => AwesomeDebouncePromise(searchFunction, wait));

  // The async callback is run each time the text changes,
  // but as the search function is debounced, it does not
  // fire a new request on each keystroke
  const searchResults = useAsync(async () => {
    if (inputText.length === 0) {
      return Promise.resolve();
    } else {
      return debouncedSearchFunction(inputText);
    }
  }, [debouncedSearchFunction, inputText]);

  // Return everything needed for the hook consumer
  return {
    inputText,
    setInputText,
    searchResults,
  };
};

export function useThrottle<Params extends unknown[]>(cb: (...args: Params) => unknown, delay: number) {
  const cbRef = useRef(cb);
  useEffect(() => {
    cbRef.current = cb;
  });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  return useCallback(throttle((...args) => cbRef.current(...args), delay) as (...args: Params) => unknown, [
    delay,
  ]);
}
