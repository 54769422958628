export default function WalletIcon() {
  return (
    <svg width="20" height="15" viewBox="0 0 20 15" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 3C0 1.34315 1.34315 0 3 0H17C18.6569 0 20 1.34315 20 3V5H12.5C11.1193 5 10 6.11929 10 7.5C10 8.88071 11.1193 10 12.5 10H20V12C20 13.6569 18.6569 15 17 15H3C1.34315 15 0 13.6569 0 12V3ZM12.5 9.16667C13.4205 9.16667 14.1667 8.42048 14.1667 7.5C14.1667 6.57953 13.4205 5.83333 12.5 5.83333C11.5795 5.83333 10.8333 6.57953 10.8333 7.5C10.8333 8.42048 11.5795 9.16667 12.5 9.16667Z"
        fill="#222222"
      />
    </svg>
  );
}
