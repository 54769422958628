import { SVGProps } from "react";

export default function ChatIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg width="22" height="18" viewBox="0 0 22 18" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.67478 2.2627C8.74014 2.2627 7.98246 3.02037 7.98246 3.95501V4H12.8181C14.0231 4 15 4.97685 15 6.18186V10.6793H16.1124H16.4536L16.6948 10.9205L17.8367 12.0624V11.503V10.6793H18.6604H18.6604C19.5951 10.6793 20.3528 9.92162 20.3528 8.98698V3.95501C20.3528 3.02037 19.5951 2.2627 18.6604 2.2627H9.67478ZM15 12.3266H15.7712L17.1576 13.713C18.0161 14.5715 19.484 13.9634 19.484 12.7493V12.2243C20.9302 11.8575 22.0001 10.5472 22.0001 8.98698V3.95501C22.0001 2.11057 20.5049 0.615356 18.6604 0.615356H9.67478C7.83034 0.615356 6.33512 2.11057 6.33512 3.95501V4H2.18186C0.976852 4 0 4.97685 0 6.18186V12.8181C0 14.0231 0.976852 15 2.18186 15H3.47848V16.7285C3.47848 17.3008 4.16125 17.5975 4.57966 17.207L6.94428 15H12.8181C14.0231 15 15 14.0231 15 12.8181V12.3266Z"
        className="fill-current"
      />
    </svg>
  );
}
