import { useTranslation } from "next-i18next";
import clsx from "clsx";
import { useContext } from "react";
import { NotificationsContext } from "@/utils/reactContexts";
import CloseButton from "@/components/ui/svg/CloseButton";

type Props = {
  count: number;
};

export default function NotificationBadge({ count }: Props) {
  const { t } = useTranslation("notifications");

  const { showNotifications, setShowNotifications } = useContext(NotificationsContext);

  return (
    <div
      onClick={() => setShowNotifications((s) => !s)}
      title={t("notifications.title")}
      className={clsx(
        "w-7 h-7 rounded-full absolute lg:-left-3.5 text-xs",
        "leading-0.95 flex items-center justify-center cursor-pointer",
        count &&
          (showNotifications
            ? "bg-orange text-white"
            : "box-border border-2 border-orange-4 bg-notification-badge text-white"),
        !count && "bg-purple-6 text-grey-dark"
      )}
    >
      {showNotifications && <CloseButton className="w-2.5 h-2.5" />}
      {!showNotifications &&
        (count ? (
          Math.min(9, count) + (count > 9 ? "+" : "")
        ) : (
          <svg width="12" height="14" viewBox="0 0 12 14" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M5.84228 13.9092C7.00405 13.9092 7.94553 12.9678 7.94553 11.806H3.7391C3.73924 12.9678 4.68061 13.9092 5.84228 13.9092ZM0.382834 10.2372C0.637724 10.4921 0.960664 10.6281 1.31764 10.6281H10.6824C11.0394 10.6281 11.3622 10.4921 11.6172 10.2372C12.144 9.71045 12.1271 8.84357 11.5662 8.33378L11.1413 7.95987C10.8694 7.70498 10.7164 7.36495 10.7164 6.99104V4.91754C10.7164 2.70804 9.01683 0.0906982 6.12752 0.0906982H5.87263C2.98336 0.0906982 1.28374 2.69108 1.28374 4.91754V6.99104C1.28374 7.36495 1.1308 7.72181 0.858838 7.95987L0.43394 8.33378C-0.127047 8.84357 -0.144143 9.7103 0.382834 10.2372Z"
              fill="#222222"
            />
          </svg>
        ))}
    </div>
  );
}
