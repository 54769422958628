import { ApolloCache, gql } from "@apollo/client";

import { MarkNotificationsAsReadMutation } from "@/graphql/types";
import { getAuthUser } from "@/utils/oAuth/storage";

export function markNotificationsAsReadUpdate(
  cache: ApolloCache<unknown>,
  { data }: { data?: MarkNotificationsAsReadMutation | null }
) {
  // Remove notifications from cache
  data?.notificationsMarkAsRead.forEach((notif) => {
    cache.evict({
      id: cache.identify(notif),
    });
  });
  cache.gc();
  const me = getAuthUser();
  if (!me) return;

  // Update unread notifications count
  cache.updateFragment(
    {
      id: `PrivateUser:${me.id}`,
      fragment: gql`
        fragment Me on PrivateUser {
          id
          unreadNotificationsCount
        }
      `,
    },
    (me) => {
      if (!me) return me;
      const removed = data?.notificationsMarkAsRead.length ?? 0;
      return {
        ...me,
        unreadNotificationsCount: (me.unreadNotificationsCount ?? removed) - removed,
      };
    }
  );
}
