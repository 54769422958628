import { useTranslation } from "next-i18next";
import clsx from "clsx";
import Image from "next/image";

import ArtistNames from "@/components/reusable/ArtistNames";
import { NO_IMAGE_URL } from "@/utils/constants";
import {
  SearchArtistFragment,
  SearchLabelFragment,
  SearchPlaylistFragment,
  SearchTrackFragment,
} from "@/graphql/types";
import { useWindowSize } from "@/components/WindowSizeProvider";

interface Props {
  track?: SearchTrackFragment;
  artist?: SearchArtistFragment;
  label?: SearchLabelFragment;
  playlist?: SearchPlaylistFragment;
  small?: boolean;
}

export default function SearchResult({ track, artist, label, playlist, small }: Props) {
  const { t } = useTranslation("common");
  const { width } = useWindowSize();
  const isMobile = width < 1024;

  if (playlist) return <PlaylistResult playlist={playlist} small={small} />;

  let imageSize = isMobile ? "40" : "48";
  if (small) imageSize = "32";
  return (
    <div
      className={clsx(
        "w-full rounded-md transition relative bg-dark-6/40 backdrop-blur-lg hover:bg-dark-hover",
        "before:border before:bg-dark-stroke",
        "before:border-gradient before:rounded-md",
        "ease-quart-out duration-300 flex items-center",
        small ? "h-12 px-2" : "h-16 px-2"
      )}
    >
      <div className="flex-shrink-0 flex items-center">
        <Image
          className={clsx("bg-purple-5", track ? "rounded" : "rounded-full")}
          alt=""
          src={
            track?.thumbnailMinifiedImageUrl ?? artist?.avatarUrl ?? label?.owner.avatarUrl ?? NO_IMAGE_URL
          }
          width={imageSize}
          height={imageSize}
        />
      </div>
      {track ? (
        <div className={clsx("flex flex-col ml-3 min-w-0", !small && "lg:ml-4")}>
          <ArtistNames
            // TODO: Add verified to artists in API SearchArtist
            artists={track.artists.map((a) => ({ ...a, verificationState: null }))}
            artistClassName={clsx(
              "font-normal uppercase mb-1 truncate",
              small ? "text-11 leading-1.2" : "text-xs leading-1.2"
            )}
            disableShowAllOnHover
          />
          <div
            className={clsx(
              "text-sm font-medium leading-1.1",
              small ? "truncate" : " lg:text-base lg:leading-1.1 line-clamp-2"
            )}
          >
            {track.title}
          </div>
        </div>
      ) : (
        <div className={clsx("flex flex-col ml-3 min-w-0", !small && "lg:ml-4")}>
          <div
            className={clsx(
              "text-sm font-medium leading-1.1",
              small ? "truncate" : " lg:text-base lg:leading-1.1 line-clamp-2"
            )}
          >
            {artist?.name ?? label?.name}
          </div>
          {(artist || label) && (
            <div
              className={clsx(
                "mt-1 opacity-50 lowercase",
                small ? "text-11 leading-0.75 tracking-wider" : "text-sm lg:text-base"
              )}
            >
              {t("common.counts.song", { count: artist?.releasedTracksCount ?? label?.releasedTracksCount })}
            </div>
          )}
        </div>
      )}
    </div>
  );
}

function PlaylistResult({ playlist, small }: { playlist: SearchPlaylistFragment; small?: boolean }) {
  const { t } = useTranslation("common");
  const { width } = useWindowSize();
  const isMobile = width < 1024;

  let imageSize = isMobile ? "40" : "48";
  if (small) imageSize = "32";
  return (
    <div
      className={clsx(
        "w-full rounded-md transition bg-dark-6/40 backdrop-blur-lg hover:bg-dark-hover",
        "before:border before:bg-dark-stroke",
        "before:border-gradient before:rounded-md",
        "ease-quart-out duration-300 flex items-center",
        small ? "h-12 px-2" : "h-16 px-2"
      )}
    >
      <div className="flex-shrink-0 flex items-center">
        <Image
          className="bg-purple-5 rounded"
          alt=""
          src={playlist.thumbnailUrl ?? NO_IMAGE_URL}
          width={imageSize}
          height={imageSize}
        />
      </div>
      <div className={clsx("flex flex-col ml-3 min-w-0", !small && "lg:ml-4")}>
        <div
          className={clsx(
            "text-sm font-medium leading-1.1",
            small ? "truncate" : " lg:text-base lg:leading-1.1 line-clamp-2"
          )}
        >
          {playlist.title}
        </div>
        <div
          className={clsx(
            "mt-1 opacity-50",
            small ? "text-11 leading-0.75 tracking-wider" : "text-sm lg:text-base"
          )}
        >
          {t("common.counts.song", { count: playlist.elementsCount })}
        </div>
      </div>
    </div>
  );
}
